import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap'; // Import React Bootstrap components
import './Home.css'; // Keep your custom styles

function Home() {
  const [showText, setShowText] = useState(true);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Trigger the initial fade-in animation
    const timer = setTimeout(() => setIsVisible(true), 100);
    return () => clearTimeout(timer);
  }, [showText]);

  useEffect(() => {
    if (showText) {
      const timer = setTimeout(() => {
        setIsVisible(false); // Trigger fade-out
        setTimeout(() => setShowText(false), 1000); // Delay removal after fade-out
      }, 3500); // Show for 3.5 seconds
      return () => clearTimeout(timer);
    }
  }, [showText]);

  return (
    <div>
      <Container fluid id="home" className="d-flex flex-column align-items-start justify-content-center py-5">
        <Row>
          <Col>
            {showText ? (
              <div
                className={`text-start ${
                  isVisible ? "fade-in" : "fade-out"
                }`}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                  width: "100vw",
                  position: "absolute",
                  top: -100,
                  left: 0,
                  fontFamily: "Urbanist",
                  fontSize: "3vh",
                }}
              >
                <div className="css-typing">
                  <p>Welcome to BitLitic</p>
                </div>
              </div>
            ) : (
              <div
                className={`text-start ${
                  isVisible ? "fade-in" : "fade-out"
                }`}
                style={{ marginLeft: "50px" }}
              >
                <h1 className="display-4 fw-bold">BitLitic</h1>
                <div className="css-typing">
                  <p>Innovating one bit at a time</p>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Home;
