import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css';

function Header({ setCurrentTab }) {
  const navigate = useNavigate(); // Hook for navigation

  const handleNavigation = (tab) => {
    setCurrentTab(tab); // Update the current tab
    navigate('/'); // Navigate to the home route
  };

  return (
    <header className="custom-header">
      <nav className="custom-nav">
        <div className="nav-container">
          <ul className="nav-links">
            <li
              className="nav-item"
              onClick={() => handleNavigation('home')}
            >
              Home
            </li>
            <li
              className="nav-item"
              onClick={() => handleNavigation('products')}
            >
              Products
            </li>
            <li
              className="nav-item"
              onClick={() => handleNavigation('aboutus')}
            >
              About Us
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;