import React, { useEffect, useState, useRef } from "react";
import Matter from "matter-js";
import GridView from "./GridView"; // Ensure the correct path
import spender from "../../Assets/spender.png";
import fortune from "../../Assets/fortune.png";
import pryority from "../../Assets/pryority.png";
import DuoDate from "../../Assets/DuoDate.png";
import caseRace from "../../Assets/caseRace.png";

const CircleAttraction = () => {
  const sceneRef = useRef(null);
  const [circles, setCircles] = useState([]);
  const [isCircleViewVisible, setIsCircleViewVisible] = useState(true);

  useEffect(() => {
    const { Engine, Render, Runner, World, Bodies, Events, Body } = Matter;

    const engine = Engine.create();
    const { world } = engine;

    engine.gravity.y = 0;
    engine.gravity.x = 0;

    const render = Render.create({
      element: sceneRef.current,
      engine: engine,
      options: {
        width: window.innerWidth,
        height: window.innerHeight,
        background: "transparent",
        wireframes: false,
      },
    });

    Render.run(render);

    const runner = Runner.create();
    Runner.run(runner, engine);

    const radius = 80;
    const icons = [
      pryority,
      fortune,
      spender,
      DuoDate,
      caseRace
    ];
    const circleBodies = [];
    const newCircles = [];

    for (let i = 0; i < 5; i++) {
      const x = Math.random() * window.innerWidth;
      const y = Math.random() * window.innerHeight;

      const body = Bodies.circle(x, y, radius, {
        restitution: 0.8,
        friction: 0.1,
        render: {
          fillStyle: "rgba(128, 128, 128, 0)",
        },
      });

      World.add(world, body);

      circleBodies.push(body);

      newCircles.push({
        id: i,
        x,
        y,
        radius,
        image: icons[i % icons.length],
        isHovered: false,
      });
    }

    setCircles(newCircles);

    Events.on(engine, "beforeUpdate", () => {
      const centerX = window.innerWidth / 2;
      const centerY = window.innerHeight / 2;

      circleBodies.forEach((body, index) => {
        const vectorX = centerX - body.position.x;
        const vectorY = centerY - body.position.y;
        const distance = Math.sqrt(vectorX ** 2 + vectorY ** 2);

        const forceMagnitude = 0.004;

        const force = {
          x: (vectorX / distance) * forceMagnitude,
          y: (vectorY / distance) * forceMagnitude,
        };

        Body.applyForce(body, body.position, force);

        setCircles((prev) => {
          const updated = [...prev];
          updated[index].x = body.position.x;
          updated[index].y = body.position.y;
          return updated;
        });
      });
    });

    const handleResize = () => {
      render.canvas.width = window.innerWidth;
      render.canvas.height = window.innerHeight;
    };

    window.addEventListener("resize", handleResize);

    return () => {
      Render.stop(render);
      World.clear(world);
      Engine.clear(engine);
      render.canvas.remove();
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMouseEnter = (id) => {
    setCircles((prev) =>
      prev.map((circle) =>
        circle.id === id ? { ...circle, isHovered: true } : circle
      )
    );
  };

  const handleMouseLeave = (id) => {
    setCircles((prev) =>
      prev.map((circle) =>
        circle.id === id ? { ...circle, isHovered: false } : circle
      )
    );
  };

  const handleCircleClick = (id) => {
    console.log(`Circle ${id} clicked`);
    setIsCircleViewVisible(false); // Switch to GridView
  };

  if (!isCircleViewVisible) {
    return <GridView closeCircle={() => setIsCircleViewVisible(true)} />
  }

  return (
    <div
      ref={sceneRef}
      style={{
        position: "absolute",
        top: -80,
        left: 0,
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      {circles.map((circle) => (
        <div
          key={circle.id}
          onMouseEnter={() => handleMouseEnter(circle.id)}
          onMouseLeave={() => handleMouseLeave(circle.id)}
          onClick={() => handleCircleClick(circle.id)} // Directly handle click here
          style={{
            position: "absolute",
            width: `${circle.radius * 2}px`,
            height: `${circle.radius * 2}px`,
            top: `${circle.y - circle.radius}px`,
            left: `${circle.x - circle.radius}px`,
            borderRadius: "50%",
            overflow: "hidden",
            backgroundColor: circle.isHovered
              ? "rgba(98, 0, 238, 0.5)"
              : "rgba(128, 128, 128, 0.1)",
            pointerEvents: "auto",
            backdropFilter: "blur(2px)",
 
          }}
        >
          <img
            src={circle.image}
            alt="Circle Icon"
            style={{
              width: "40%",
              height: "40%",
              objectFit: "contain",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderRadius: "20%",
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default CircleAttraction;
