import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./GirdView.css"; // Import the CSS file

const GridView = ({closeCircle}) => {
  return (
    <Container fluid className="p-4">
      <Row className="gy-4 mb-4">
        {/* Top Row: Two Horizontal Rectangles */}
        <Col xs={12} sm={4}>
          <div className="rectangle placeholder-text">Title (Coming Soon)</div>
        </Col>
        <Col xs={12} sm={6}>
          <div className="rectangle placeholder-text">Description, Genre (Coming Soon)</div>
        </Col>
        <Col xs={12} sm={2}>
        <div className="rectangle placeholder-text"><button style={{color:"red", backgroundColor:"transparent", border:"none", fontSize:"20px", fontFamily: "Urbanist"}} className="x-text" onClick={closeCircle}>X</button> </div>
        </Col>
      </Row>

      <Row className="gy-4 mb-4">
        {/* Middle Row: Two Uneven Rectangles */}
        <Col xs={12} sm={8}>
          <div className="large-rectangle placeholder-text">Images (Coming Soon)</div>
        </Col>
        <Col xs={12} sm={4}>
          <div className="extra-small-rectangle placeholder-text mb-4">Apple (Coming Soon)</div>
          <div className="extra-small-rectangle placeholder-text">Android (Coming Soon)</div>
        </Col>
      </Row>

      <Row className="gy-4">
        {/* Bottom Row: Three Rectangles */}
        <Col xs={12} sm={4}>
          <div className="rectangle placeholder-text">Countries (Coming Soon)</div>
        </Col>
        <Col xs={12} sm={4}>
          <div className="rectangle placeholder-text">Age (Coming Soon)</div>
        </Col>
        <Col xs={12} sm={4}>
          <div className="rectangle placeholder-text">Ratings, tutorial (Coming Soon)</div>
        </Col>
      </Row>
    </Container>
  );
};

export default GridView;
