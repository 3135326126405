import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import mission from "../../Assets/mission.png";
import "./Mission.css";

const Mission = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Trigger animation when the component mounts
    const timer = setTimeout(() => setIsVisible(true), 100);
    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);

  return (
    <div
      className={`container-fluid d-flex align-items-center ${
        isVisible ? "fade-in" : "invisible"
      }`}
      style={{
        height: "82vh",
        color: "#fff",
      }}
    >
      <div className="col-md-1 d-flex justify-content-center align-items-center"></div>
      <div className="row w-100">
        {/* Image Section */}
        <div className="col-md-5 d-flex justify-content-center align-items-center mb-4">
          <div
            className="image-container shadow"
            style={{
              overflow: "hidden",
              borderRadius: "10px",
            }}
          >
            <img
              src={mission}
              alt="Mission Visual"
              className="img-fluid"
              style={{
                scale: "0.85",
                transition: "transform 0.5s ease-in-out",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(0.85)")
              }
            />
          </div>
        </div>

        <div className="col-md-1 d-flex justify-content-center align-items-center"></div>

        {/* Mission Statement Section */}
        <div
          className="col-md-4 mission-text-container"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <h2 className="mission-heading">
            Our Mission
          </h2>
          <p className="mission-paragraph">
            At <strong style={{ color: "#ff6a95" }}>BitLitic</strong>, our
            mission is to enhance everyday life by creating intuitive,
            minimalist, and enjoyable applications that seamlessly integrate
            into daily routines. We strive to elevate the quality of life for
            everyone by delivering innovative solutions that are as functional
            as they are delightful, making technology accessible, efficient, and
            empowering.
          </p>
          <p className="mission-paragraph">
            <strong style={{ color: "#ffc371" }}>Contact us</strong> to learn
            more about the <strong style={{ color: "#ff6a95" }}>US</strong> or
            have any questions.
          </p>
          <p className="mission-paragraph">
            Reach out at:{" "}
            <a
              href="mailto:BitLitic@gmail.com"
              style={{
                color: "#ffc371",
                textDecoration: "none",
                fontWeight: "bold",
              }}
            >
              BitLitic@gmail.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Mission;