import React from 'react';
import "./pdfViewer.css"
//import pdf from "./PrivacyPolicyPryority.pdf"
const PdfViewer = ({ pdf }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "85vh",
        //backgroundColor: "#fff", // Set a white background to override Vanta
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <iframe
        src={pdf}
        title="Privacy Policy"
        className="responsive-iframe"
        style={{
          height: "100%",
          border: "none",
        }}
      />
    </div>
  );
};

export default PdfViewer;