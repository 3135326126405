import React, { useState, useEffect, useRef } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Header from './Pages/Header/Header';
import Home from './Pages/MainPages/Home';
import Footer from './Pages/Footer/Footer';
import Products from './Pages/MainPages/Mission/Mission';
import Effect from "./Pages/AnimatedBackground/vanta.dots";
import CircleAttraction from './Pages/MainPages/Product/InititalProduct';
import PdfViewer from './Pages/MainPages/Product/pdfViewer';
import * as THREE from "three";
import PrivacyPolicyPryority from "./Pages/MainPages/Product/Pryority/PrivacyPolicyPryority.pdf"
import TermsAndConditionsPryority from "./Pages/MainPages/Product/Pryority/TermsAndConditionsPryority.pdf"

function App() {
  const [currentTab, setCurrentTab] = useState('home');
  const vantaRef = useRef(null);
  const vantaEffect = useRef(null);
  const location = useLocation(); // React Router hook to get current path

  useEffect(() => {
    if (!vantaEffect.current && vantaRef.current && location.pathname !== '/Pryority/Privacy-Policy') {
      try {
        vantaEffect.current = new Effect({
          el: vantaRef.current,
          THREE: THREE,
          color: 0xa15e7b, // Customize colors
          backgroundColor: 0x000000, // Background color
          size: 1.5, // Adjust size
          spacing: 20,
          showLines: false,
        });
      } catch (error) {
        console.error("Failed to initialize Vanta effect:", error);
      }
    }

    return () => {
      if (vantaEffect.current) {
        vantaEffect.current.destroy();
        vantaEffect.current = null;
      }
    };
  }, [location.pathname]);

  const renderContent = () => {
    switch (currentTab) {
      case 'home':
        return <Home />;
      case 'products':
        return <CircleAttraction />;
      case 'aboutus':
        return <Products />;
      default:
        return <Home />;
    }
  };

  return (
    <div
      className="App"
      ref={vantaRef}
      style={{
        minHeight: "100vh",
        background: location.pathname === "/Pryority/Privacy-Policy" ? "#fff" : undefined, // Disable Vanta background
      }}
    >
      <Header setCurrentTab={setCurrentTab} />
      <Routes>
        <Route path="/" element={<main>{renderContent()}</main>} />
        <Route path="/Pryority/Privacy-Policy" element={<PdfViewer pdf={PrivacyPolicyPryority} />} />
        <Route path="/Pryority/Terms-and-Conditions" element={<PdfViewer pdf={TermsAndConditionsPryority} />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;